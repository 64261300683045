::selection {
    background-color: $pink;
    color: white;
}
body {
    padding: 1rem 2rem;
}

#inputTodoForm {
    display: flex;
    .control {
        flex: 1;
    }

    input[type="text"] {
        border-radius: 4px 0 0 4px ;
    }
    .button {
        border-radius: 0 4px 4px 0;
    }
}

#deleteAll {
    margin: 1rem 0 1rem auto;
    display: block;
}

#todoUl {
    .box {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        padding: 1rem 3rem 1rem;

        .fas  {
            display: none;
            height: 20px;
        }

        // no selection color
        -webkit-user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -ms-user-select: none;

        li {
            flex: 1;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            overflow-wrap: anywhere;
            hyphens: auto;

            span { flex: 1; }
        }
        
        .delete {
            right: 1.2rem;
            margin-top: 0.2rem;
            transform: translateX(2.45rem);
        }
        &.completed {
            background: $success;
            .lt {
                text-decoration: line-through;
            }
            .fas { 
                display: inline-block;
                position: absolute;
                transform: translate(-1.8rem, .2rem);
            }
        }
        .date {
            text-decoration: none;
            flex: 1 1 100%;
            text-align: right;
            font-size: .8rem;
            margin-top: 1rem;
        } 
    }
}
